import { createSelector } from '@reduxjs/toolkit';

import {
  IS_SURGERY_PLUS,
  SHOW_BCBS_LINK,
  SHOW_BOARD_CERTIFIED,
  SHOW_CHAT,
  SHOW_CHAT_FEEDBACK,
  SHOW_CHAT_SCENARIO,
  SHOW_CLIENT_FEATURED,
  SHOW_PHONE_NUMBER,
  SHOW_TELEHEALTH,
  SHOW_WHEELCHAIR_ACCESSIBLE,
  SHOW_SERVICE_COST,
  SOCT,
  SOCT_PROVIDER_LOOKUP,
} from 'utils/constants';
import { CONFIG_SLICE_NAME } from '../slicesNames';
import * as selectConfig from './selectConfig';
import * as selectClient from './selectClient';
import * as selectNetworks from './selectNetworks';
import ensembleProviderLookupConfig from './localConfigurations/ensembleProviderLookupConfig';

/* ************************** */
/* Client Level Feature Flags */
/* ************************** */

/** This enables the WMTBenefitsNotifierModal to be shown. Client Level Only
 * @returns {boolean} Value of HIGH_QUALITY_INTRO_MODAL */
export const highQualityIntroModal = (state) =>
  state[CONFIG_SLICE_NAME].values.HIGH_QUALITY_INTRO_MODAL;

/** Enables the app to show provider costs details. Client Level Only
 * @returns {boolean} Value of SHOW_COST */
export const showCost = (state) => state[CONFIG_SLICE_NAME].values.SHOW_COST;

/** Enables a "/disclaimer" route in the App router. Client Level Only
 *
 * TODO - because the Disclaimer component also relies on client specific disclaimer content,
 * this should potentially return false if there is no content available.
 * @returns {boolean} Value of SHOW_DISCLAIMER */
export const showDisclaimer = (state) => state[CONFIG_SLICE_NAME].values.SHOW_DISCLAIMER;

/** Shows LGBTQ+ component (results, drawer, filter). Client Level Only
 * @returns {boolean} Value of SHOW_OUTCARE */
export const showOutcare = (state) => state[CONFIG_SLICE_NAME].values.SHOW_OUTCARE;

/** Shows the available filter counts for results in the filter menu. Client Level Only
 * @returns {boolean} Value of SHOW_FILTER_COUNTS */
export const showFilterCounts = (state) => state[CONFIG_SLICE_NAME].values.SHOW_FILTER_COUNTS;

/** Unknown use. Local client config only.
 * @deprecated Not used anywhere in the code.
 * @returns {boolean} Value of USE_GENDER_ICONS */
export const useGenderIcons = (state) => state[CONFIG_SLICE_NAME].values.USE_GENDER_ICONS;

/** Enables the style guide route in the app router. Generally only enabled in testing and staging. Local config only.
 * @returns {boolean} Value of ENABLE_STYLEGUIDE_ROUTE */
export const enableStyleguide = (state) => state[CONFIG_SLICE_NAME].values.ENABLE_STYLEGUIDE_ROUTE;

/** Enables places in AutocompleteSuggestions and ProviderAutocomplete
 * @deprecated No clients ever set to false.
 * @returns {boolean} Value of ENABLE_PLACE_SEARCH */
export const enablePlaceSearch = (state) => state[CONFIG_SLICE_NAME].values.ENABLE_PLACE_SEARCH;

/** Enables the network/region selector to be shown. Primarily in the header. Local config only.
 * @returns {boolean} Value of SHOW_REGION_SELECTOR */
export const showRegionSelector = (state) => state[CONFIG_SLICE_NAME].values.SHOW_REGION_SELECTOR;

/** Enables the Benefit Diff filter. Primarily used by Icuba. Local config only.
 * @returns {boolean} Value of SHOW_BENEFIT_DIFF */
export const showBenefitDiff = (state) => state[CONFIG_SLICE_NAME].values.SHOW_BENEFIT_DIFF;

/** Enables scores to be shown throughout the app. Client level only.
 * @returns {boolean} Value of SHOW_DR_SCORE */
export const showDrScore = (state) => state[CONFIG_SLICE_NAME].values.SHOW_DR_SCORE;

/** Enables subspecialties of providers to be shown. Client level only.
 * @returns {boolean} Value of SHOW_SUBSPECIALTIES */
export const showSubspecialties = (state) => state[CONFIG_SLICE_NAME].values.SHOW_SUBSPECIALTIES;

/** Enables the Provider Strengths section in the profile. Client level only.
 * @returns {boolean} Value of SHOW_PROVIDER_STRENGTHS */
export const showProviderStrengths = (state) =>
  state[CONFIG_SLICE_NAME].values.SHOW_PROVIDER_STRENGTHS;

/** Enables the High Performing ribbon and badge to be shown. Primarily used by Walmart. Client level only.
 * @returns {boolean} Value of SHOW_HIGH_PERFORMING_RIBBON */
export const showHighPerformingRibbon = (state) =>
  state[CONFIG_SLICE_NAME].values.SHOW_HIGH_PERFORMING_RIBBON;

/** Hides the section header in the results list for promotions. Local config only.
 * @returns Value of HIDE_CLIENT_PROMOTE_HEADER */
export const hideClientPromoteHeader = (state) =>
  state[CONFIG_SLICE_NAME].values.HIDE_CLIENT_PROMOTE_HEADER;

/** Use the provided network to hide the network select drop down box. Client level only.
 * @returns Value of HAS_PORTAL_INTEGRATION */
export const hasPortalIntegration = (state) =>
  state[CONFIG_SLICE_NAME].values.HAS_PORTAL_INTEGRATION;

/** Shows the Embold logo on the login page. Local config only.
 * @returns {boolean} Value of EMBOLD_LOGO_PRESENT */
export const showEmboldLogo = (state) => state[CONFIG_SLICE_NAME].values.EMBOLD_LOGO_PRESENT;

/** Removes the link to Embold white page on Login Page. Local config only.
 * @returns {boolean} Value of NO_WELCOME_LINK */
export const hideWelcomeLink = (state) => state[CONFIG_SLICE_NAME].values.NO_WELCOME_LINK;

/** Enables use of specialty autocomplete. CLient level only.
 * @returns {boolean} Value of USE_NEW_SPECIALTY_AUTOCOMPLETE */
export const useNewSpecialtyAutocomplete = (state) =>
  state[CONFIG_SLICE_NAME].values.USE_NEW_SPECIALTY_AUTOCOMPLETE;

/** Enables the use of the share button for providers and places. Client level only.
 * @returns {boolean} Value of SHOW_SHARE */
export const showShare = (state) => state[CONFIG_SLICE_NAME].values.SHOW_SHARE;

/** Shows provider credentials as filterable items in filter menu. Client level only.
 * @returns {boolean} Value of SHOW_PROVIDER_CREDENTIALS_FILTER */
export const showProviderCredentialsFilter = (state) =>
  state[CONFIG_SLICE_NAME].values.SHOW_PROVIDER_CREDENTIALS_FILTER;

/** Shows subspecialty scores within provider specializations component. Client level only.
 * @returns {boolean} Value of SHOW_SUBSPECIALTY_SCORES */
export const showSubspecialtyScores = (state) =>
  state[CONFIG_SLICE_NAME].values.SHOW_SUBSPECIALTY_SCORES;

/** Shows "Hospital Affiliations" component & content, if `null` do not display. Client level only.
 * @returns {boolean} Value of SHOW_HOSPITAL_AFFILIATIONS */
export const showHospitalAffiliations = (state) =>
  state[CONFIG_SLICE_NAME].values.SHOW_HOSPITAL_AFFILIATIONS;

/** Shows "Support" button & content, if `null` do not display.
 * @returns {boolean} Value of HIDE_SUPPORT */
export const hideSupport = (state) => state[CONFIG_SLICE_NAME].values.HIDE_SUPPORT;

/** Shows Embold icon for providers over the recommended provider threshold. Client level only.
 * @returns {boolean} Value of SHOW_EMBOLD_RECOMMENDED */
export const showEmboldRecommended = (state) =>
  state[CONFIG_SLICE_NAME].values.SHOW_EMBOLD_RECOMMENDED;

/** Denotes a "provider lookup" instance of PG. Several UI elements are hidden on these instances such as "support" links and landing page sections
 * @returns {boolean} Value of PROVIDER_LOOKUP */
export const providerLookup = (state) => state[CONFIG_SLICE_NAME].values.PROVIDER_LOOKUP;

/** Denotes a "provider lookup" instance of PG. Uses generic versions of certain branded UI elements.
 * @returns {boolean} Value of IS_WHITELABELED */
export const isWhitelabeled = (state) => state[CONFIG_SLICE_NAME].values.IS_WHITELABELED;

/** Text to be displayed in the left column of the login screen. Saved in Django as Markdown, must be passed through <ReactMarkdown> for proper display.
 * @returns {string} Value of WELCOME_MESSAGE */
export const welcomeMessageMd = (state) => state[CONFIG_SLICE_NAME].values.WELCOME_MESSAGE;

/** Enables the "BCBS Total Care Provider" attribute in the profile Credentials and Experience section
 * @returns {boolean} Value of PROVIDER_LOOKUP */
export const showTotalCare = (state) => state[CONFIG_SLICE_NAME].values.SHOW_TOTAL_CARE;

/** Enables the "Contact BCBS Route"
 * @returns {boolean} Value of SHOW_CONTACT_BCBS_ROUTE */
export const showContactBcbsRoute = (state) =>
  state[CONFIG_SLICE_NAME].values.SHOW_CONTACT_BCBS_ROUTE;

/**
 * In order to enable rewards, clients must be configured for SSO with the HAS_PORTAL_INTEGRATION flag, and they must also be closed access.
 *
 * This feature flag enables the /rewards routes and enables the fetch to the /rewards-campaign endpoint
 */
export const enableRewards = createSelector(
  [hasPortalIntegration, selectConfig.isClosedAccess],
  (isSsoIntegrated, isClosedAccess) => isSsoIntegrated && isClosedAccess
);
/**
 * Hides the welcome title on the login page "Welcome to Provider Guide".
 * Some clients such as SOCT specifically choose to hide this.
 * @returns {boolean}
 */
export const hideWelcomeTitle = createSelector([selectClient.name], (emboldClient) => {
  switch (emboldClient) {
    case SOCT:
    case SOCT_PROVIDER_LOOKUP:
      return true;
    default:
      return false;
  }
});

/** This enables a button to be shown in the header (and other places) that logs the user out to allow for them to change networks
 * by logging back in.
 */
export const showNetworkLogoutButton = createSelector(
  [selectConfig.name],
  // currently ensemble provider lookup is the only client using this feature
  (configName) => configName === ensembleProviderLookupConfig.CONFIG_NAME
);

/* *************************** */
/* Network Level Feature Flags */
/* *************************** */

/*
 The pattern for grabbing network level feature flags should be as followed.
 1. Get the current selected network slug,
 2. Also check for the value on the client level.
 3. If there is no current network slug return the client level value, even if it's undefined.
 4. Get the network config object for the current network slug, and return the value of the desired flag.
 5. If there is no value in the network config, return the client level value, even if it's undefined
 */

const currentNetworkConfig = createSelector(
  [
    (state) => state[CONFIG_SLICE_NAME].currentNetworkSlug,
    (state) => state[CONFIG_SLICE_NAME].values.REGION_SELECTOR_CODES,
  ],
  (slug, allNetworks) => {
    if (!slug) return undefined;
    // TODO TECH-3574 - track down all instances where we are accessing the client config before it's potentially loaded
    return allNetworks[slug]?.config;
  }
);

/** Enables the app to show telehealth details & features, such as the telehealth search filter.
 * Network level value.
 * @returns {boolean} Value of SHOW_TELEHEALTH, fallback to false  */
export const showTelehealth = selectNetworks.getNetworkConfigValue(SHOW_TELEHEALTH, false);

/** Enables the Wheelchair Accessible filter
 * Network level value.
 * @returns {boolean} Value of SHOW_WHEELCHAIR_ACCESSIBLE, fallback to false  */
export const showWheelchairAccessible = selectNetworks.getNetworkConfigValue(
  SHOW_WHEELCHAIR_ACCESSIBLE,
  false
);

/** Enables the Board Certified Filter
 * Network level value.
 * @returns {boolean} Value of SHOW_BOARD_CERTIFIED, fallback to false  */
export const showBoardCertified = selectNetworks.getNetworkConfigValue(SHOW_BOARD_CERTIFIED, false);

/** Enables the BCBS link in the nav menu, and the route in the App router.
 * Network level only.
 * @returns {boolean} Returns value of SHOW_BCBS_LINK */
export const showBcbsLink = selectNetworks.getNetworkConfigValue(SHOW_BCBS_LINK, false);

/** Enables the network phone number to be shown.
 * Network level only.
 * @returns {boolean} The value of SHOW_PHONE_NUMBER, fallback to true */
export const showPhoneNumber = selectNetworks.getNetworkConfigValue(SHOW_PHONE_NUMBER, true);

/** Determines if we want to display client featured provider content.
 * Network level only.
 * @returns {boolean} The value of SHOW_CLIENT_FEATURED, fallback to false */
export const showClientFeatured = selectNetworks.getNetworkConfigValue(SHOW_CLIENT_FEATURED, false);

/** Determines if the selected network is a SurgeryPlus network.
 * Network level only.
 * @returns {boolean} The value of IS_SURGERY_PLUS, fallback to false */
export const isSurgeryPlusNetwork = selectNetworks.getNetworkConfigValue(IS_SURGERY_PLUS, false);

export const costEstimatorLink = createSelector([currentNetworkConfig], (networkConfig) => {
  if (!networkConfig) return undefined;
  return networkConfig.COST_ESTIMATOR_LINK;
});

export const costEstimatorDescription = createSelector([currentNetworkConfig], (networkConfig) => {
  if (!networkConfig) return undefined;
  return networkConfig.COST_ESTIMATOR_LINK_DESCRIPTION;
});

/** Enables the "Schedule" button in the Provider profile and compare tables.
 * @returns {boolean} Value of SHOW_SCHEDULE_BUTTON */
export const showScheduleButton = createSelector(
  [(state) => state[CONFIG_SLICE_NAME].values.SHOW_SCHEDULE_BUTTON, isSurgeryPlusNetwork],
  (showScheduleBtn, isSurgeryPlus) => showScheduleBtn && !isSurgeryPlus
);

/** Enables the use of the chat modal.
 * @returns {boolean} Value of SHOW_CHAT */
export const enableChat = selectNetworks.getNetworkConfigValue(SHOW_CHAT, false);

/** Enables the scenarioId dropdown on the chat terms screen.
 * @returns {boolean} Value of SHOW_CHAT_SCENARIO */
export const enableChatScenario = selectNetworks.getNetworkConfigValue(SHOW_CHAT_SCENARIO, false);

/** Enables form prompting user feedback after a completed chat session.
 * @returns {boolean} Value of SHOW_CHAT_FEEDBACK */
export const enableChatFeedback = selectNetworks.getNetworkConfigValue(SHOW_CHAT_FEEDBACK, false);

/** Enables the use of service costs via the provider-cost endpoint. Network level feature.
 * @returns {boolean} Value of SHOW_SERVICE_COST for the current network */
export const showServiceCost = () => true;
