export function getEnv() {
  return 'production';
}

export function getClient() {
  const hostname = window?.location?.hostname || '';
  if (process.env.NODE_ENV !== 'production') return 'local';
  if (hostname === 'wpg.emboldhealth.com') return 'walmart';
  return hostname.split('.')?.[0];
}

export function selectToken(apiTokens) {
  const env = getEnv();
  if (env === 'local' || env === 'testing') return apiTokens.dev;
  if (env === 'staging') return apiTokens.staging;
  return apiTokens.prod;
}

export function selectMapKey(azureMapsKeys) {
  const env = getEnv();
  if (env === 'local' || env === 'testing') return azureMapsKeys.dev;
  if (env === 'staging') return azureMapsKeys.staging;
  return azureMapsKeys.prod;
}
