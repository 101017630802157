/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import { get, camelCase } from 'lodash';
import * as Sentry from '@sentry/react';
import { LOCAL_STORAGE_VERSION_NUMBER, PLACE_RESULT_TYPE, PROVIDER_RESULT_TYPE } from './constants';

export function getEnv() {
  return 'production';
}

export function getClient() {
  const hostname = window?.location?.hostname || '';
  if (process.env.NODE_ENV !== 'production') return 'local';
  if (hostname === 'wpg.emboldhealth.com') return 'walmart';
  if (hostname === 'walmart.emboldhealth.com') return 'walmart-national';
  return hostname.split('.')?.[0];
}

export function selectToken(apiTokens) {
  const env = getEnv();
  if (env === 'local' || env === 'testing') return apiTokens.dev;
  if (env === 'staging') return apiTokens.staging;
  return apiTokens.prod;
}

export function getCookie(cname) {
  // i got the getCookie from https://www.w3schools.com/js/js_cookies.asp
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function deleteCookie(cname) {
  // i got the deleteCookie from https://www.w3schools.com/js/js_cookies.asp
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function setCookie(cname, cvalue, exdays, overrideCookieConsent = false) {
  if (!overrideCookieConsent && !getCookie('cookiesAccepted')) return;
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export function setLocalStorageExpire(key, value, daysToExpire, contentVersion) {
  const daysToMilliseconds = 86400000 * daysToExpire;
  const now = new Date();

  const item = {
    value: value,
    version: LOCAL_STORAGE_VERSION_NUMBER,
    contentVersion: contentVersion || null,
    expiration: daysToExpire ? now.getTime() + daysToMilliseconds : null,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageExpire(key, contentVersion) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (item.expiration && now.getTime() > item.expiration) {
    localStorage.removeItem(key);
    return null;
  }

  if (contentVersion && contentVersion !== item.contentVersion) {
    localStorage.removeItem(key);
    return null;
  }

  if (LOCAL_STORAGE_VERSION_NUMBER !== item.version) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function distance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist *= 1.609344;
  }
  return +dist.toFixed(2);
}

export function buildLocationUrl(location, mapsURL) {
  return `${mapsURL}${location.address1} ${location.city} ${location.state}`;
}

export function isValidEmail(email) {
  const [user, domain] = email.split('@');
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const userRegex =
    /(^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*$|^"([\001-\010\013\014\016-\037!#-[\]-\177]|\\[\001-\011\013\014\016-\177])*"$)/i.test(
      user
    );
  const domainRegex = /((?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+)(?:[A-Z0-9]{2,63})$/i.test(
    domain
  );

  return [emailRegex, userRegex, domainRegex].every(Boolean);
}

export function isValidPhoneNumber(phoneNumber) {
  return /^(\+1|1)?\(?(\d{3})\)?[-]?(\d{3})[-]?(\d{4})$/.test(phoneNumber);
}

export function isValidUrl(urlString) {
  // From bleach library used in fusion
  const domains =
    'ac ad ae aero af ag ai al am an ao aq ar arpa as asia at au aw ax az ' +
    'ba bb bd be bf bg bh bi biz bj bm bn bo br bs bt bv bw by bz ca cat ' +
    'cc cd cf cg ch ci ck cl cm cn co com coop cr cu cv cx cy cz de dj dk ' +
    'dm do dz ec edu ee eg er es et eu fi fj fk fm fo fr ga gb gd ge gf gg ' +
    'gh gi gl gm gn gov gp gq gr gs gt gu gw gy hk hm hn hr ht hu id ie il ' +
    'im in info int io iq ir is it je jm jo jobs jp ke kg kh ki km kn kp ' +
    'kr kw ky kz la lb lc li lk lr ls lt lu lv ly ma mc md me mg mh mil mk ' +
    'ml mm mn mo mov mobi mp mq mr ms mt mu museum mv mw mx my mz na name nc ne ' +
    'net nf ng ni nl no np nr nu nz om org pa pe pf pg ph pk pl pm pn post ' +
    'pr pro ps pt pw py qa re ro rs ru rw sa sb sc sd se sg sh si sj sk sl ' +
    'sm sn so sr ss st su sv sx sy sz tc td tel tf tg th tj tk tl tm tn to ' +
    'tp tr travel tt tv tw tz ua ug uk us uy uz va vc ve vg vi vn vu wf ws ' +
    'xn xxx ye yt yu za zip zm zw';

  const domainsArray = domains.split(' ');
  const protocolsArray = [
    'ed2k',
    'ftp',
    'ftps',
    'http',
    'https',
    'irc',
    'mailto',
    'news',
    'gopher',
    'nntp',
    'telnet',
    'webcal',
    'xmpp',
    'callto',
    'feed',
    'urn',
    'aim',
    'rsync',
    'tag',
    'ssh',
    'sftp',
    'rtsp',
    'afs',
    'data',
    'file',
  ];

  const urlPattern = new RegExp(
    `${String.raw`\(*\b(?:(?:${protocolsArray.join(
      '|'
    )}):/{{0,3}}(?:(?:\w+:)?\w+@)?)?([\w-]+\.)+(?:${domainsArray.join(
      '|'
    )})(?:\:[0-9]+)?(?!\.\w)\b(?:[/?][^\s\{{\}}\|\\\^\[\]`}\`<>"]*)?`,
    'im'
  );
  return !!urlPattern.test(urlString);
}

export function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch {
    return false;
  }
  return isValidUrl(url.href) && (url.protocol === 'http:' || url.protocol === 'https:');
}

export function containsUnsafeCharacters(string) {
  return /[<>&]|""|''|^"$|^'$/gm.test(string);
}

export function containsHtml(string) {
  const el = document.createElement('div');
  el.innerHTML = string;

  if (Array.from(el.childNodes).some((node) => node.nodeType === 1)) {
    return true;
  }

  return false;
}

export const checkInvalidTextField = (message) => {
  const trimmedMessage = message.replace(/\s+/g, ' ').trim();
  const words = trimmedMessage.split(' ');
  const invalidUrl = words.some((word) => isValidUrl(word) || isValidEmail(word));
  const invalidHtml = containsHtml(trimmedMessage);
  const invalidCharacters = containsUnsafeCharacters(trimmedMessage);
  // approximate regex used by the azure waf
  const wafApprovedChars = /^[a-zA-Z0-9 .!?",;:()-]+$/;
  const wafUnapprovedCharacters = words.some((word) => !wafApprovedChars.test(word));
  let errorMessage = '';
  if (invalidUrl) {
    errorMessage = 'Message cannot contain URLs';
  } else if (invalidHtml) {
    errorMessage = 'Message cannot contain HTML';
  } else if (invalidCharacters) {
    errorMessage = "Message cannot contain '<', '>', or '&' or unsafe quotes";
  } else if (wafUnapprovedCharacters && words[0] !== '') {
    errorMessage = 'Message cannot contain invalid characters';
  }
  return errorMessage;
};

// To convert degrees lat and long to miles...
// A degree latitude is ~69 miles anywhere on the globe
// A degree longitude differs based on latitude and can be calculated with this formula:
// length of 1 degree of Longitude = cosine (latitude in decimal degrees) * length of degree (miles) at equator
// hence:
// const milesPerDegreeLatitude = 69.172;
// const milesPerDegreeLongitude = Math.cos( (Math.PI * southCoords[1]) / 180 ) * milesPerDegreeLatitude;
export function getMilageInLatLong(lat, mileage) {
  const milesPerDegreeLatitude = 69.172;
  const milesPerDegreeLongitude = Math.cos((Math.PI * lat) / 180) * milesPerDegreeLatitude;

  const mileageLng = mileage / milesPerDegreeLongitude;
  const mileageLat = mileage / milesPerDegreeLatitude;

  return {
    mileageLng,
    mileageLat,
  };
}

export function getBounds(results, mileage = 0.5, padding = 0.2) {
  if (
    results.length &&
    (!get(results[0], 'latitude') || !get(results[0], 'longitude')) &&
    (!get(results[0], 'closestVisibleLocation.latitude') ||
      !get(results[0], 'closestVisibleLocation.longitude'))
  ) {
    console.log(
      'Utls.js - results must be passed to getBounds() with valid latitude and longitude properties'
    );
    return;
  }

  if (!results.length) {
    console.log('Utls.js - no results passed to getBounds()');
    return;
  }
  const firstResultLatitude =
    get(results[0], 'latitude') && get(results[0], 'longitude')
      ? get(results[0], 'latitude')
      : get(results[0], 'closestVisibleLocation.latitude');

  const firstResultLongitude =
    get(results[0], 'latitude') && get(results[0], 'longitude')
      ? get(results[0], 'longitude')
      : get(results[0], 'closestVisibleLocation.longitude');

  if (results.length === 1) {
    // if there is only one result, we calculate and create buffer (.5 mile by default) on each side
    // calculations explaned at bottom of this file
    const { mileageLat, mileageLng } = getMilageInLatLong(firstResultLatitude, mileage);

    // eslint-disable-next-line consistent-return
    return [
      firstResultLongitude - mileageLng,
      firstResultLatitude - mileageLat,
      firstResultLongitude + mileageLng,
      firstResultLatitude + mileageLat,
    ];
  }

  let neCoords = {
    longitude: firstResultLongitude,
    latitude: firstResultLatitude,
  };
  let swCoords = {
    longitude: firstResultLongitude,
    latitude: firstResultLatitude,
  };

  if (results.length) {
    results.forEach((result) => {
      const lat =
        result.latitude && result.longitude
          ? result.latitude
          : get(result, 'closestVisibleLocation.latitude');

      const lng =
        result.latitude && result.longitude
          ? result.longitude
          : get(result, 'closestVisibleLocation.longitude');

      if (neCoords.latitude < lat) {
        neCoords.latitude = lat;
      }
      if (neCoords.longitude < lng) {
        neCoords.longitude = lng;
      }
      if (swCoords.latitude > lat) {
        swCoords.latitude = lat;
      }
      if (swCoords.longitude > lng) {
        swCoords.longitude = lng;
      }
    });
  }

  // The above code calculates the exact bounds of the coords on the map. The below code
  // adds a little padding to those coords - so markers don't get cut off
  // The padding is set proportionally to the height and width of the bounding box
  // e.g. An icon whos coords are at the very top of the map will be cut off - because the icons anchor is 'bottom' and the icon will extend above the map

  const width = Math.abs(neCoords.longitude - swCoords.longitude);
  const height = Math.abs(neCoords.latitude - swCoords.latitude);

  // eslint-disable-next-line no-param-reassign
  padding = padding < 1 && padding > 0 ? padding : 0.2;

  swCoords.longitude -= width * padding;
  swCoords.latitude -= height * padding;
  neCoords.longitude += width * padding;
  neCoords.latitude += height * padding;

  // format for mapbox
  swCoords = [swCoords.longitude, swCoords.latitude];
  neCoords = [neCoords.longitude, neCoords.latitude];
  // eslint-disable-next-line consistent-return
  return [...swCoords, ...neCoords];
}

export function getPlaces(data, isPromote = false) {
  const places = [];

  let dataId = get(data, 'entityId');
  let dataPlaces = get(data, 'places');
  let dataType = 'provider';

  if (isPromote) {
    dataId = get(data, 'id');
    dataPlaces = get(data, 'locations');
    dataType = 'promote';
  }

  if (dataPlaces?.length) {
    dataPlaces.forEach((place) => {
      if (place.latitude !== null || place.longitude !== null) {
        places.push({ ...place, clientFeaturedLocation: data.clientFeatured });
      } else if (process.env.NODE_ENV === 'production') {
        Sentry.captureMessage(
          `Null lat/long data for place ${get(place, 'id')} on ${dataType} ${dataId}`
        );
      }
    });
  }

  const uniquePlaces = places.reduce((p, c) => {
    if (!p.some((el) => el.latitude === c.latitude && el.longitude === c.longitude)) p.push(c);
    return p;
  }, []);

  return uniquePlaces;
}

export function getPlacesWithinRadius(data, latitude, longitude, radius) {
  const allPlaces = getPlaces(data);

  return radius
    ? allPlaces.filter(
        (place) => distance(latitude, longitude, place.latitude, place.longitude) <= radius
      )
    : allPlaces;
}

/**
 * This response will include additional fields that we do not use.
 * @typedef {{addresses:{address: GeocodedAddress}[]}} GeocodeResult - JSON converted atlas API response.
 * @typedef {{
 *   countrySubdivision: string,
 *   countrySecondarySubdivision: string,
 *   countryCodeISO3: string,
 *   municipality: string,
 *   neighborhood: string
 *   postalCode: string,
 *   streetName: string,
 *   streetNameAndNumber: string,
 * }} GeocodedAddress
 * {
 *   countrySubdivision - State or Province (abbreviated),
 *   countrySecondarySubdivision - County,
 *   countryCodeISO3 - country/region name (abbreviated),
 *   municipality - City / Town
 *   neighbourhood - Neighbourhood (british spelling)
 *   postalCode - ZIP code (5 digit)
 *   streetName - ex: "Arapahoe Street"
 *   streetNameAndNumber - ex: "940 Arapahoe Street"
 * }
 */

/**
 * @param {{results:GeocodeResult[]}} response
 * @returns {{address: string, neighbourhood: string, city: string, state: string, fullAddress: string}}
 */
export function formatGeolocate(response) {
  const { addresses } = response;
  if (!addresses?.length) return null;
  if (!addresses[0]?.address) return null;

  const {
    countrySubdivision,
    countrySecondarySubdivision,
    countryCodeISO3,
    municipality,
    neighbourhood,
    postalCode,
    streetName,
    streetNameAndNumber,
    freeformAddress,
  } = addresses[0].address;

  const isOutsideOfUSA = countryCodeISO3 !== 'USA';

  const fullAddress =
    streetNameAndNumber && municipality
      ? `${streetNameAndNumber}, ${municipality}${isOutsideOfUSA ? ` ${countryCodeISO3}` : ''}`
      : null;

  return {
    state: countrySubdivision || null,
    city: municipality || null,
    zip: postalCode || null,
    county: countrySecondarySubdivision || null,
    country: countryCodeISO3 || null,
    neighbourhood: neighbourhood || null,
    street: streetName || null,
    fullAddress: fullAddress || freeformAddress,
    isOutsideOfUSA,
  };
}

export function detectWebGLSupport() {
  const canvas = document.createElement('canvas');
  const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

  return Boolean(gl && gl instanceof WebGLRenderingContext);
}

export const validateLatLong = (lat, lng) =>
  lat !== undefined &&
  lat !== null &&
  lng !== undefined &&
  lng !== null &&
  Number(lat) >= -90 &&
  Number(lat) <= 90 &&
  Number(lng) >= -180 &&
  Number(lng) <= 180;

export const validateBounds = (bounds) =>
  validateLatLong(bounds?.ne?.latitude, bounds?.ne?.longitude) &&
  validateLatLong(bounds?.sw?.latitude, bounds?.sw?.longitude);

export const parseJSON = (input, fallback) => {
  try {
    return JSON.parse(input);
  } catch (e) {
    return fallback;
  }
};

// FRONT END SORTS
export const sortByDistance = (results, lat, long) =>
  results.sort((a, b) => {
    const A =
      get(a, 'distanceInMiles[0]') ||
      distance(
        lat,
        long,
        get(a, 'closestVisibleLocation.latitude'),
        get(a, 'closestVisibleLocation.longitude')
      );
    const B =
      get(b, 'distanceInMiles[0]') ||
      distance(
        lat,
        long,
        get(b, 'closestVisibleLocation.latitude'),
        get(b, 'closestVisibleLocation.longitude')
      );

    return A - B;
  });

// Used for sorting all provider places when no location is used in query
export const sortProviderPlacesByDistance = (places, lat, long) =>
  places.sort(
    (a, b) =>
      distance(lat, long, a.latitude, a.longitude) - distance(lat, long, b.latitude, b.longitude)
  );

export const sortByName = (value, results) => {
  const camelCaseValue = camelCase(value);
  return results.sort((a, b) => {
    const A = a[camelCaseValue].toUpperCase();
    const B = b[camelCaseValue].toUpperCase();

    if (A > B) {
      return 1;
    }
    if (A < B) {
      return -1;
    }
    return 0;
  });
};

export const sortByQuality = (results) => results.sort((a, b) => b.scoringOrder - a.scoringOrder);

export const getClosestVisibleLocation = (mapBounds, provider) => {
  let closestVisibleLocation;
  for (let i = 0; i < provider.places.length; i += 1) {
    if (
      // is current location in the visible map area
      mapBounds.sw.latitude < provider.places[i].latitude &&
      provider.places[i].latitude < mapBounds.ne.latitude &&
      mapBounds.sw.longitude < provider.places[i].longitude &&
      provider.places[i].longitude < mapBounds.ne.longitude
    ) {
      closestVisibleLocation = provider.places[i];
      break;
    } else {
      // if none of the locations are in search bounds
      // eslint-disable-next-line no-lonely-if
      if (i + 1 === provider.places.length) {
        // eslint-disable-next-line prefer-destructuring
        closestVisibleLocation = provider.places[0];
      }
    }
  }
  return closestVisibleLocation;
};

// the following 2 functions come from here: https://stackoverflow.com/a/18473154
const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

export const describeArc = ({ x = 21, y = 21, radius = 18, startAngle = 90, endAngle }) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(
    ' '
  );

  return d;
};

export const getScoreArc = (score) => {
  const endAngle = score * 3.6 + 90; // convert % to º and rotate clockwise 90º
  const arc1 = describeArc({ endAngle });
  const arc2 = describeArc({ startAngle: endAngle, endAngle: 450 });
  return [arc1, arc2];
};

// Added to prepare for when fusion switches to camel case json payloads from snake case
export const mapCamel = function (arr) {
  const camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => camelizeKeys(v));
    }
    if (obj != null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  return Array.isArray(arr) ? arr.map((element) => camelizeKeys(element)) : camelizeKeys(arr);
};

// @todo: test
export function getToday({ offsetYear = 0 } = {}) {
  const today = new Date();
  const yyyy = today.getFullYear() + offsetYear;
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = `0${dd}`;
  if (mm < 10) mm = `0${mm}`;

  return `${yyyy}-${mm}-${dd}`;
}

export function withinDateRange(dateCheck, start, end, report = true) {
  if (!(start || end) || !dateCheck) {
    console.error('withinDateRange must have a dateCheck and a start or end argument');
    if (report) {
      Sentry.captureException(
        new Error('withinDateRange must have a dateCheck and a start or end argument')
      );
    }
    return false;
  }

  if (![dateCheck, start, end].every((arg) => arg instanceof Date || arg === null)) {
    console.error('withinDateRange arguments must be a valid Date object or null');
    if (report) {
      Sentry.captureException(
        new Error('withinDateRange arguments must be a valid Date object or null')
      );
    }
    return false;
  }

  if (start && end) {
    return dateCheck >= start && dateCheck <= end;
  }
  if (start) {
    return dateCheck >= start;
  }
  return dateCheck <= end;
}

export const isTodayInDateRange = (start, end) => {
  if (!start || !end) {
    console.error('isTodayInDateRange must have "start" and "end" arguments');
    return false;
  }
  const startDate = new Date(start);
  const endDate = new Date(end);
  const today = new Date();
  return withinDateRange(today, startDate, endDate, false);
};

// use this when React needs a key and there is no uuid to supply one, basically the same hash function in Java
// taken from https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
export function hashForKey(param) {
  // in some cases param is an integer
  const stringifiedParam = param.toString();
  let hash = 0;
  let chr;
  let i;
  if (stringifiedParam.length === 0) return hash;
  for (i = 0; i < stringifiedParam.length; i += 1) {
    chr = stringifiedParam.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

/**
 * This function logs a console message only when in a development environment
 * @param {string} message The message to be logged in the console
 * @param {('error'|'warn'|'log')} type Type of console message
 */
export function logDevMessage(message, type = 'error') {
  if (process.env.NODE_ENV === 'development') {
    console[type](message);
  }
}

/**
 * @param {string} phoneNumber A string representing a phone number
 * @returns {string | null} A valid E164 US phone number, or null if it can't be transformed successfully
 */
export function transformToUsaE164(phoneNumber) {
  if (!phoneNumber) return '';

  const numbersOnly = phoneNumber.replace(/\D/g, '');
  const matched = numbersOnly.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (matched) {
    return `+1${matched[1]}${matched[2]}${matched[3]}`;
  }

  return null;
}

/**
 * This is a function used for testing purposes. It will return a promise after 3 seconds. You can simulate a failure with the forceFail param.
 * @param {any} resolveValue The values to be returned by resolving the promise
 * @param {Boolean} forceFail This will force the promise to reject for testing a failure
 * @param {any} rejectValue The value returned on a failure
 * @returns Promise
 */
export function mockFetchCall(resolveValue, forceFail = false, rejectValue = 'Test failure') {
  return new Promise((res, rej) => {
    setTimeout(() => {
      if (forceFail) rej(new Error(rejectValue));
      res(resolveValue);
    }, 3000);
  });
}

/**
 * Determines if the result type is a provider or place
 * @param {Object} result The result object
 * @returns {PROVIDER_RESULT_TYPE | PLACE_RESULT_TYPE} A string constant for provider or place type
 */
export function determineResultType(result) {
  if (result?.firstName && result?.lastName) return PROVIDER_RESULT_TYPE;
  return PLACE_RESULT_TYPE;
}

/**
 * @param {Object} result The result object
 * @returns {Boolean} True if the result is a provider type
 */
export function resultIsProviderType(result) {
  return determineResultType(result) === PROVIDER_RESULT_TYPE;
}

/**
 * @param {Object} result The result object
 * @returns {Boolean} True if the result is a place type
 */
export function resultIsPlaceType(result) {
  return determineResultType(result) === PLACE_RESULT_TYPE;
}

/**
 * This function prioritizes the possible parameters that can be returned as text, returning the highest available priority
 * @param {Object} suggestion
 * @param {string?} suggestion.serviceName
 * @param {string?} suggestion.subspecialtyName
 * @param {string?} suggestion.specialtyName
 * @param {string?} suggestion.name
 * @param {string?} suggestion.entityName
 * @returns {string} The text to be shown in the input after selecting a suggestion
 */
export function getAutocompleteSuggestionText(suggestion, useNewSpecialtyAutocomplete = false) {
  if (suggestion.searchTerm && !useNewSpecialtyAutocomplete) {
    return `${suggestion.searchTerm} (${suggestion.subspecialtyName || suggestion.specialtyName})`;
  }

  if (suggestion.serviceName) return suggestion.serviceName;
  if (suggestion.subspecialtyName) return suggestion.subspecialtyName;
  if (suggestion.specialtyName) return suggestion.specialtyName;
  if (suggestion.name) return suggestion.name;
  return suggestion.entityName;
}

export const VALID_HEADING_VARIANTS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

/** Calculates the next heading level
 * @param {string} variant The heading level to work from
 * @example
 * const nextHeading = getNextHeadingLevel("h3");
 * console.log(nextHeading); // "h4"
 */
export function getNextHeadingLevel(variant) {
  const currentIndex = VALID_HEADING_VARIANTS.findIndex((el) => el === variant);
  const nextIndex = currentIndex + 1;
  if (currentIndex === -1) {
    logDevMessage(`Unable to find valid variant ${variant}`);
    return variant;
  }

  if (nextIndex >= VALID_HEADING_VARIANTS.length) {
    return variant;
  }

  return VALID_HEADING_VARIANTS[nextIndex];
}

/** Calculates the next heading level
 * @param {string} variant The heading level to work from
 * @example
 * const prevHeading = getPreviousHeadingLevel("h3");
 * console.log(prevHeading); // "h2"
 */
export function getPreviousHeadingLevel(variant) {
  const currentIndex = VALID_HEADING_VARIANTS.findIndex((el) => el === variant);
  const prevIndex = currentIndex - 1;
  if (currentIndex === -1) {
    logDevMessage(`Unable to find valid variant ${variant}`);
    return variant;
  }

  if (prevIndex < 0) {
    return variant;
  }

  return VALID_HEADING_VARIANTS[prevIndex];
}

/**
 * @param {Array} array Array of items of any type
 * @returns {Array} shuffled array of the same items
 * https://stackoverflow.com/a/12646864
 */
export function shuffleArray(array) {
  const dupe = [...array];
  // eslint-disable-next-line no-plusplus
  for (let i = dupe.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [dupe[i], dupe[j]] = [dupe[j], dupe[i]];
  }
  return dupe;
}

/**
 * Opens a URL in a new tab with noopener and noreferrer
 * @param {string} url
 */
export function openInNewTab(url) {
  window.open(url, '_blank', 'noopener,noreferrer');
}

/**
 * Opens a URL in this tab with noopener and noreferrer
 * @param {string} url
 */
export function openInSameTab(url) {
  window.open(url, '_self', 'noopener,noreferrer');
}

export function showInMaps(location, mapsURL) {
  openInNewTab(buildLocationUrl(location, mapsURL));
}

/**
 * Returns tier designation content object with `text` and `tooltipMessage` properties
 * @param {string|number} tier
 */
export const getTierDesignationContent = (tier) => {
  if (!tier) return null;

  const tierDesignationContent = {
    1: {
      text: 'Tier 1',
      tooltipMessage:
        'Using a Tier 1 (Value) provider at a Tier 1 location may result in lower costs to you',
    },
    2: {
      text: 'Tier 2',
      tooltipMessage: 'Using a Tier 2 provider may result in higher costs to you',
    },
    'Non-Tiered': {
      text: 'Non-Tiered',
      tooltipMessage: 'Using a Non-Tiered provider may result in no cost to you.',
    },
    'Non-Tier': {
      text: 'Non-Tiered',
      tooltipMessage: 'Using a Non-Tiered provider may result in no cost to you.',
    },
  };

  return tierDesignationContent[tier];
};
